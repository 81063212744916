import { DatePicker, Input, Row, Select, Typography } from "antd";
import type { GetProps } from "antd";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { Form, useField, useFormikContext } from "formik";
import { FilterFunc } from "rc-select/lib/Select";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import RocketApi from "../../utils/api/rocket-api";
import capitalizeFirstLetter from "../../utils/capitalizeLetter";
import { InitialValues, KeyValuePair, getDefaultInitialValues } from "./types";

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const { RangePicker } = DatePicker;
const { Text } = Typography;

interface FilterField {
    label: string;
    placeholder: string;
    type: "select" | "input" | "rangepicker" | "multiple";
    defaultValue?: any;
    extraConfig?: {
        depends?: string[];
        filterKey: string;
        optionsURL: string;
    };
}

const FieldRangePicker: FC<FilterField> = (props) => {
    const formik = useFormikContext<InitialValues>();
    const { t } = useTranslation();
    const [periods, setPeriods] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(dayjs());

    const fetchPeriodsData = async (controller: AbortController) => {
        const api = new RocketApi();

        let filters: KeyValuePair[] = [];
        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }

        const response = await api.request(
            `${formik.values.settings.provider}/periods`,
            {
                body: JSON.stringify({ filters }),
                controller,
            },
        );

        const dataPeriods = await response?.json();

        const periodsBackend = dataPeriods.map(
            (period: { label: string; start: string; end: string }) => ({
                label: period.label,
                value: [dayjs(period.start), dayjs(period.end)],
            }),
        );

        localStorage.setItem(
            "isCurrent",
            // @ts-ignore
            formik.values.filters.start_time === dataPeriods[0].start,
        );

        setPeriods(periodsBackend);
    };

    useEffect(() => {
        const controller = new AbortController();
        fetchPeriodsData(controller);

        return () => controller.abort();
    }, [formik.values.filters]);

    const onRangeChange = (
        dates: null | (Dayjs | null)[],
        dateStrings: string[],
    ) => {
        if (dates) {
            const selectedStartDate =
                dateStrings[0] !== "" ? dayjs(dateStrings[0]) : null;
            const selectedEndDate =
                dateStrings[1] !== "" ? dayjs(dateStrings[1]) : null;
            setSelectedMonth(
                selectedStartDate ? selectedStartDate : selectedEndDate,
            );

            if (selectedStartDate?.isValid()) {
                formik.setFieldValue("filters.start_time", dateStrings[0]);
                if (!dates[1]) {
                    return;
                } else {
                    formik.setFieldValue("filters.end_time", dateStrings[1]);
                }
            } else {
                return;
            }
        } else {
            setSelectedMonth(null);
        }
    };

    const disabledDate: RangePickerProps["disabledDate"] = (
        current,
        { from },
    ) => {
        if (from) {
            return Math.abs(current.diff(from, "month")) >= 1;
        }

        return false;
    };

    return (
        <>
            <Text>{t(`shared.${props.label.toLowerCase()}`)}</Text>
            <RangePicker
                style={{ width: "100%" }}
                defaultValue={[
                    // @ts-ignore
                    dayjs(formik.values.filters.start_time),
                    // @ts-ignore
                    dayjs(formik.values.filters.end_time),
                ]}
                allowClear={true}
                presets={periods}
                format="YYYY-MM-DD"
                onCalendarChange={onRangeChange}
                disabledDate={disabledDate}
                inputReadOnly={true}
            />
        </>
    );
};

const FieldMock: FC<FilterField> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <Text>{t(`shared.${props.label}`)}</Text>
            <Input disabled placeholder={props.placeholder} />
        </>
    );
};

interface Option {
    label: string;
    value: string;
}

const filterOptions: FilterFunc<Option> = (input, option) => {
    if (!option) return false;

    const userInput = input.toLowerCase();
    const labelIncludes = option.label.toLowerCase().includes(userInput);
    const valueIncludes = option.value.toLowerCase().includes(userInput);

    return labelIncludes || valueIncludes;
};

const FieldSelect: FC<FilterField> = (props) => {
    const { t } = useTranslation();
    const formik = useFormikContext<InitialValues>();

    const [field, meta, helpers] = useField({
        name: `filters.${props.extraConfig!.filterKey}`,
        multiple: props.type === "multiple",
    });

    const [options, setOptions] = useState<Option[]>([]);
    const [disabled, setDisabled] = useState(false);

    const views = formik.values.settings.frontend.views;
    const filtersPerView = formik.values.settings.frontend.filters;

    const fetchOptions = async (controller: AbortController) => {
        let filters: KeyValuePair[] = [];

        for (const key in formik.values.filters) {
            // @ts-ignore
            if (Array.isArray(formik.values.filters[key])) {
                // @ts-ignore
                for (const item of formik.values.filters[key]) {
                    filters.push({ key, value: item });
                }
            } else {
                // @ts-ignore
                filters.push({ key, value: formik.values.filters[key] });
            }
        }

        const api = new RocketApi();
        const provider =
            formik.values.settings.provider === "azure/licenses"
                ? "azure"
                : formik.values.settings.provider;
        const response = await api.request(
            `${provider}${props.extraConfig!.optionsURL}`,
            {
                body: JSON.stringify({ filters }),
                controller,
            },
        );

        if (response?.status === 200) {
            const data = (await response?.json()).map(
                (opt: Option, idx: number) => ({ key: idx, ...opt }),
            );

            formik.setFieldValue(
                `options.${props.extraConfig!.filterKey}`,
                data,
            );

            if (props.extraConfig!.filterKey === "invoice_type") {
                localStorage.setItem(
                    `invoice_type_${formik.values.settings.provider}`,
                    JSON.stringify(data),
                );
            }
            setOptions(data);
        }
    };

    useEffect(() => {
        const controller = new AbortController();

        if (props.extraConfig!.optionsURL && !props.extraConfig!.depends) {
            fetchOptions(controller);
        }

        return () => controller.abort();
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        if (props.extraConfig!.depends) {
            const dependencies = props.extraConfig!.depends;
            const shouldDisable = dependencies.some(
                (dep) =>
                    // @ts-ignore
                    !formik.values.filters[dep],
            );

            setDisabled(shouldDisable);
        }

        if (
            props.extraConfig!.optionsURL.includes(
                formik.values.filters.view?.toLowerCase(),
            )
        ) {
            fetchOptions(controller);
        }
    }, [formik.values.filters]);

    const whichView = () => {
        const currentFilter = field.name.split(".")[1];

        let index = filtersPerView.indexOf(currentFilter);
        if (index === -1) {
            return {
                prev: null,
                current: null,
                next: null,
            };
        }

        const prevItem = index === 0 ? views[index] : views[index - 1];

        const nextItem =
            index === views.length - 1 ? views[index] : views[index + 1];
        return { prev: prevItem, current: views[index], next: nextItem };
    };

    // HACK: ignore bc it doesn't need to be added to applied filters
    const ignore = [
        "filters.invoice_type",
        "filters.cost",
        "filters.catalog",
        "filters.period",
        "filters.billing_cycle_type",
    ];

    return (
        <div style={{ width: "100%" }}>
            <Text>{t(`shared.${props.label.toLowerCase()}`)}</Text>
            <Select
                mode={props.type == "multiple" ? "multiple" : undefined}
                allowClear
                showSearch
                disabled={disabled}
                key={field.value}
                value={field.value || null}
                placeholder={props.placeholder}
                options={
                    props.label.toLowerCase() === "reseller"
                        ? options.map((o) => {
                              o.label = capitalizeFirstLetter(o.label);
                              return o;
                          })
                        : options
                }
                filterOption={filterOptions}
                onClear={() => {
                    helpers.setValue(props.defaultValue);
                    if (!ignore.includes(field.name)) {
                        let currentAppliedFilters =
                            formik.values.appliedFilters;
                        const clearedItem = {
                            label: field.value
                                ? options.find(
                                      (option) => option.value === field.value,
                                  )?.label
                                : "",
                            value: field.value,
                        };

                        const indexClear = currentAppliedFilters
                            .map((e) => e.id)
                            .indexOf(clearedItem.value);
                        const newAppliedFilters =
                            formik.values.appliedFilters.slice(0, indexClear);

                        const filterToClear = currentAppliedFilters[indexClear];
                        formik.setFieldValue(
                            "filters.view",
                            filterToClear.view,
                        );

                        for (
                            let index = indexClear;
                            index < currentAppliedFilters.length;
                            index++
                        ) {
                            const filterKey =
                                index > views.length
                                    ? filtersPerView[views.length]
                                    : filtersPerView[index];
                            formik.setFieldValue(`filters.${filterKey}`, "");
                        }
                        formik.setFieldValue(
                            "appliedFilters",
                            newAppliedFilters,
                        );
                    }
                }}
                onDeselect={(value, option) => {
                    if (props.type === "multiple") {
                        const items = field.value as string[];
                        if (items.indexOf(value) >= 0) {
                            const newValue = items.filter(
                                (item) => item !== value,
                            );

                            if (newValue.length === 0) {
                                helpers.setValue(props.defaultValue);
                            } else {
                                helpers.setValue(newValue);
                            }
                        }
                    }
                }}
                onSelect={(value, option) => {
                    if (props.type === "multiple") {
                        if (field.value.indexOf(value) == -1) {
                            helpers.setValue([...field.value, value]);
                        }
                    } else {
                        helpers.setValue(value);
                    }

                    if (!ignore.includes(field.name)) {
                        const { current, next } = whichView();
                        formik.setFieldValue("filters.view", next);
                        let currentAppliedFilters =
                            formik.values.appliedFilters;
                        currentAppliedFilters.push({
                            id: option.value,
                            name: option.label,
                            view: current as string,
                        });

                        formik.setFieldValue(
                            "appliedFilters",
                            currentAppliedFilters,
                        );
                    }
                }}
                style={{ width: "100%" }}
            />
        </div>
    );
};

export const RenderDynamicField: FC<FilterField> = (props) => {
    switch (props.type) {
        case "rangepicker":
            return <FieldRangePicker {...props} />;

        case "select":
            return <FieldSelect {...props} />;

        case "multiple":
            return <FieldSelect {...props} />;

        default:
            return <FieldMock {...props} />;
    }
};

const Filters = () => {
    const formik = useFormikContext<InitialValues>();
    const location = useLocation();

    const fields = formik.values.settings.filtersFields;
    // TODO: Recive default values from backend
    const defaultValues = getDefaultInitialValues(
        formik.values.settings.provider,
    );
    return (
        <>
            <Form>
                <Row
                    style={{
                        padding: "14px",
                        position: "fixed",
                        minWidth: "300px",
                        maxWidth: "300px",
                    }}
                >
                    {fields.map((field, idx) => {
                        // TODO: Recive default values from backend
                        const maybeDefaultValue =
                            // @ts-ignore
                            defaultValues.filters[field.extraConfig!.filterKey];
                        const defaultOrNull = maybeDefaultValue
                            ? maybeDefaultValue
                            : null;

                        // @ts-ignore
                        field.defaultValue = defaultOrNull;
                        return <RenderDynamicField key={idx} {...field} />;
                    })}
                </Row>
            </Form>
        </>
    );
};

export default Filters;
